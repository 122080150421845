.mailingList {
    width: 100%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    gap: 14px;
}

.form {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 14px;
}


.emailInput {
    border: 1px solid rgb(62, 68, 70);
    background-color: rgb(67 69 69);
    border-radius: 5px;
    width: 100%;
    max-width: 100%;
    padding-inline: 0;
    padding: 10px;
    line-height: 24px;
    font-size: 24px;
    outline-color: black;
    color: white;
}

.emailInput:disabled {
    color: lightgray;
}

.button {
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #4CAF50;
    color: white;
    font-size: 20px;
    cursor: pointer;
    font-size: 24px;
}

.button:active {
    filter:brightness(90%)
}

.button:hover {
    filter: brightness(110%)
}

.button:disabled {
    background-color: #2a472b;
    color: lightgray;
}

.info {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.title {
    font-family: 'Birtch Std';
    font-size: 50px;
    width: 100%;
    text-align: center;
}
.action {
    font-size: 18px;
    font-weight: 500;
    width: 100%;
    text-align: center;
}

.backgroundClip {
    background-clip: text;
    background-image: url('../bg.gif');
    background-position: center;
    background-size: 100%;
    color: rgba(255, 255, 255, 0.7);

    text-shadow: 0 0 10px #7534ce;
}

.link {
    color: #7534ce;
    font-weight: 600;
}

/* small media (phones) */
@media (max-width: 600px) {
    .mailingList {
        max-width: 280px;
    }

    .form {
        flex-direction: column;
    }

    .emailInput {
        max-width: 100%;
        width: auto;
    }

    .button {
        width: 100%;
    }
}

