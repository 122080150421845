.App {
  text-align: center;
  position: relative;
  overflow: auto;
  max-height: 100vh;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
  /* add aesthetic animated glow */
  animation: App-pulse-glow 7s infinite ease-in-out;
  filter: drop-shadow(0 0 0px #453162);
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-pulse-glow 7s infinite ease-in-out; /* App-logo-spin 20s infinite linear; */
  }
}

.App-page {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.App-discord-invite {
  background-image: url(./discord.svg);
  background-size: 64%;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 100%;
  overflow: hidden;
  position: absolute;
  height: 48px;
  width: 48px;
  bottom: 10px;
  right: 10px;
  border: 2px solid #5865F2;
}



.App-divider {
  height: 92px;
}

@media (max-width: 600px) {
  .App-divider {
    height: 24px;
  }
}


.App-footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 60vh;
  background-color: #14161a;
  padding-top: 128px;
  padding-bottom: 64px;
}


@keyframes App-pulse-glow {

  0%,
  100% {
    filter: drop-shadow(0 0 1px #453162) brightness(100%);
  }

  50% {
    filter: drop-shadow(0 0 20px #ad7df5) brightness(120%);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}