
.faq-container {
    text-align: left;
    width: 100%;
    max-width: 500px;
    color: white;
    display: flex;
    flex-direction: column;
    gap: 24px;
    min-height: 20vh;
}
@media (max-width: 600px) {
    .faq-container {
        max-width: 280px;
    }
}

.faq-paragraphs {
    display: flex;
    flex-direction: column;
    gap: 14px;
}

.faq-content {
    color: lightgray;
    padding-left: 24px;
}

.faq-link {
    color: #7534ce;
    font-weight: 600;
}

.faq-row {
    display: flex;
    flex-direction: column;
    gap: 14px;
}

.faq-title {
    font-size: 18px;
    cursor: pointer;
}
